import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import TopSection from '../components/TopSection';
import SEO from '../components/Seo';

const Container = styled.div`
  margin: 0 auto;
  max-width: 80rem;
  padding: 3rem 2rem;

  @media screen and (min-width: 768px) {
    padding-bottom: 10rem;
  }
`;

const Hr = styled.hr`
  height: 0.1rem;
  border: 0;
  margin: 3rem auto;
  background-color: rgba(0, 0, 0, 0.05);
`;

export default function About() {
  const data = useStaticQuery(graphql`
    {
      image1: file(relativePath: { eq: "mission.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 70)
        }
      }
      image2: file(relativePath: { eq: "a-friendly-relaxing-atmosphere.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 70)
        }
      }
      image3: file(relativePath: { eq: "background.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 70)
        }
      }
    }
  `);

  return (
    <>
      <SEO title="About" />
      <Layout>
        <TopSection title="About" />
        <Container>
          <h2>Our mission</h2>
          <p>
            It&apos;s simple: offer you the best cup of coffee we can; serve
            traditional, high quality food in a friendly and relaxing
            atmosphere; celebrate, promote and embrace diversity; give you a
            South American experience.
          </p>
          <GatsbyImage
            image={data.image1.childImageSharp.gatsbyImageData}
            alt="food, drinks and magazine on a table"
          />
          <Hr />
          <h2>Background</h2>
          <p>Cafuné is a family business run by Lívia and Sean.</p>
          <p>
            Lívia was born and raised in Fortaleza, Brazil, a huge port city in
            the North East of the country where there is an abundance of fresh
            seafood and amazing fruits. She has also lived in Colombia and
            Argentina, where she fell in love with the people, music, food and
            of course the coffee!
          </p>
          <p>
            Lívia is passionate about different cultures, languages and social
            media. You will find her front of house chatting with customers and
            always with a big smile on her face.
          </p>
          <p>
            Sean was born and raised in Preston and of Irish descent. A few
            years ago, he travelled around Chile, Argentina, Paraguay and
            Brazil. He also lived in Ecuador teaching English for a while. Sean
            met Livia in Brazil and together they moved to Buenos Aires,
            Argentina where the idea of Cafuné began.
          </p>
          <p>
            Sean is a self-taught chef who loves experimenting with different
            ingredients. Our unique fruity salad dressing is just one of his
            delicious creations.
          </p>
          <p>
            Sean loves football and a good pint of Guinness. Livia loves dancing
            and socialising with her friends. Together, they enjoy spending time
            with their young son, Davi.
          </p>
          <GatsbyImage
            image={data.image3.childImageSharp.gatsbyImageData}
            alt="map of South America on a wood wall"
          />
          <Hr />
          <h2>Atmosphere</h2>
          <p>
            The atmosphere at Cafuné reflects the attitude of the South American
            people. While they may not be the richest in the world, they are
            certainly the happiest! From the sunny Copacabana of Rio to the
            freezing mountain ranges of the Andes you will always be greeted
            with a friendly smile.
          </p>
          <GatsbyImage
            image={data.image2.childImageSharp.gatsbyImageData}
            alt="Cafuné interior"
          />
        </Container>
      </Layout>
    </>
  );
}
